body {
    color: #1A1525;
    background-color: #fff4ee;
}

a {
    &:link {
        color: #DE342B;
    }

    &:visited {
        color: #563939;
    }

    &:hover {
        color: #1A1525;
    }

    &:active {
        color: #B58776;
    }
}