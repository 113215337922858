body {
  color: #1a1525;
  background-color: #fff4ee;
}

a:link {
  color: #de342b;
}

a:visited {
  color: #563939;
}

a:hover {
  color: #1a1525;
}

a:active {
  color: #b58776;
}

/*# sourceMappingURL=index.bb436be3.css.map */
